<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Nueva Pregunta</span>
    </v-card-title>
    <v-card-subtitle>
      <br /><br />
      <span class="text-h6">evaluación: 2. evaluación Integral</span>
      <br />
      <span class="text-h6">Seccion: 2.2. Infraestrcutura</span>
    </v-card-subtitle>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-textarea counter label="Pregunta *" rows="2" />
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-card class="mx-auto" outlined color="transparent" max-width="600">
              <v-card-text>
                <label>Puntaje</label>
                <v-row class="mb-4" justify="space-between">
                  <v-col class="text-left">
                    <span class="text-h2 font-weight-light" v-text="bpm" />
                    <span class="subheading font-weight-light mr-1" />
                  </v-col>
                </v-row>

                <v-slider v-model="bpm" track-color="grey" always-dirty min="0" max="10">
                  <template v-slot:prepend>
                    <v-icon @click="decrement"> mdi-minus </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon @click="increment"> mdi-plus </v-icon>
                  </template>
                </v-slider>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-radio-group>
              <template v-slot:label>
                <div>Tipo de pregunta</div>
              </template>
              <v-radio value="1">
                <template v-slot:label>
                  <div>SI / NO</div>
                </template>
              </v-radio>
              <v-radio value="2">
                <template v-slot:label>
                  <div>Valor 1 al 5</div>
                </template>
              </v-radio>
              <v-radio value="3">
                <template v-slot:label>
                  <div>Valor 1 al 10</div>
                </template>
              </v-radio>
              <v-radio value="4">
                <template v-slot:label>
                  <div>Seleccion Multiple</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="12" sm="12" md="12" style="text-align: center">
            <v-btn elevation="2" @click="answerSum()">Agregar respuesta</v-btn>
          </v-col>
          <v-col cols="5" v-if="answers > 0" sm="5" md="5">
            <v-card class="mx-auto" outlined color="transparent" max-width="600">
              <v-card-text>
                <label>Puntaje</label>
                <v-row class="mb-4" justify="space-between">
                  <v-col class="text-left">
                    <span class="text-h2 font-weight-light" v-text="bpm2" />
                    <span class="subheading font-weight-light mr-1" />
                  </v-col>
                </v-row>

                <v-slider v-model="bpm2" track-color="grey" always-dirty min="0" max="10">
                  <template v-slot:prepend>
                    <v-icon :color="color" @click="decrement2"> mdi-minus </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon :color="color" @click="increment2"> mdi-plus </v-icon>
                  </template>
                </v-slider>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="7" v-if="answers > 0" sm="7" md="7">
            <v-textarea counter label="Respuesta *" rows="5" />
          </v-col>
          <v-col cols="5" v-if="answers > 1" sm="5" md="5">
            <v-card class="mx-auto" outlined color="transparent" max-width="600">
              <v-card-text>
                <label>Puntaje</label>
                <v-row class="mb-4" justify="space-between">
                  <v-col class="text-left">
                    <span class="text-h2 font-weight-light" v-text="bpm3" />
                    <span class="subheading font-weight-light mr-1" />
                  </v-col>
                </v-row>

                <v-slider v-model="bpm3" track-color="grey" always-dirty min="0" max="10">
                  <template v-slot:prepend>
                    <v-icon :color="color" @click="decrement3"> mdi-minus </v-icon>
                  </template>

                  <template v-slot:append>
                    <v-icon :color="color" @click="increment3"> mdi-plus </v-icon>
                  </template>
                </v-slider>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="7" v-if="answers > 1" sm="7" md="7">
            <v-textarea counter label="Respuesta *" rows="5" />
          </v-col>
        </v-row>
      </v-container>
      <small>*campo requerido</small>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="blue darken-1" text @click="dialog = false"> Cerrar </v-btn>
      <v-btn color="blue darken-1" text @click="dialog = false"> Guardar </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
  export default {
    data: () => ({
      bpm: 0,
      bpm2: 0,
      bpm3: 0,
      answers: 0,
      interval: null,
      isPlaying: false,
    }),

    computed: {
      animationDuration() {
        return `${60 / this.bpm}s`
      },
    },

    methods: {
      answerSum() {
        this.answers++
      },
      decrement() {
        this.bpm--
      },
      increment() {
        this.bpm++
      },
      toggle() {
        this.isPlaying = !this.isPlaying
      },
      decrement2() {
        this.bpm2--
      },
      increment2() {
        this.bpm2++
      },
      decrement3() {
        this.bpm3--
      },
      increment3() {
        this.bpm3++
      },
      toggle2() {
        this.isPlaying = !this.isPlaying
      },
    },
  }
</script>
